import React from 'react';
import { IconDrawnEllipse } from 'styleguide/icons';
import { FadeIn, FadeInStagger } from 'styleguide/components/FadeIn/FadeIn';
import LazyLoad from 'react-lazyload';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';

const Block = ({ title, description }: { title: string; description: string }) => (
  <div className="flex flex-col mb-14">
    <FadeIn>
      <h5 className="text-2xl font-hvMedium mb-6">{title}</h5>
      <p className="m-0">{description}</p>
    </FadeIn>
  </div>
);

const PrintMadeEasy = () => (
  <LazyLoad once placeholder={LoaderWrapper('h-[428px] flex items-center justify-center')}>
    <FadeInStagger>
      <div className="flex justify-between py-20 2xl:px-[100px] px-4 sm:px-8 flex-col lg:flex-row">
        <FadeIn>
          <div className="max-w-full mr-40 mb-12">
            <div className="relative w-96">
              <h3 className="text-4xl sm:text-5xl font-hvMedium text-nowrap ">Print Made Easy.</h3>
              <IconDrawnEllipse className="absolute !w-[140px] !h-[76px] top-[-22px] left-[170px] scale-75 sm:top-[-18px] sm:left-[250px] sm:scale-100" />
            </div>
          </div>
        </FadeIn>
        <div className="flex flex-col">
          <Block
            title="People started using us to grow."
            description="And it wasn’t just because we were offering marketing and promotional products. We were taking care of loose ends. Making the right recommendations. Checking files for free. Delivering what we promised. We were making people who were buying print online look like pros."
          />
          <Block
            title="And then word about us spread."
            description="We started earning rave reviews on Facebook, Yelp and Google. With 10,000+ reviews and a 98% satisfaction rating, we became the highest rated printing company our size or larger."
          />
          <Block
            title="All because we cared about your orders."
            description="It takes more than automation, checklists, and machines to do great work. Even though we have lots of those. It also takes heart. And you only get that by putting together a team of great individuals."
          />
        </div>
      </div>
    </FadeInStagger>
  </LazyLoad>
);

export default PrintMadeEasy;
